<template>
  <div>
      <v-dialog max-width="350" v-model="SEOBasicsDialog">
        <v-card tile flat width="100%">
            <v-card-title>
            </v-card-title>
            <v-card-text>
                <v-switch label="Page Shows on Web Searches" v-model="SEOPage.SEO"
                />
                <ContentEditableField style="padding:15px;"
                :FieldObject="SEOPage" :FieldName="'Overview'"
                :FieldValue="SEOPage.Overview" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
                <v-combobox
                        v-model="SEOPage.KeyWords"
                        :items="KeyWords"
                        label="KeyWords"
                        multiple
                        chips
                        >
                </v-combobox>
            </v-card-text>
            <v-card-text  v-if="SEOPage.SEO">
                Web Preview
                <v-list-item style="width:100%;padding-top:10px;padding-left:20px;border: 1px solid #ccc !important;" three-line> 
                                       
                    <v-list-item-content>
                        <v-list-item-title class="caption" style="margin-bottom:-6px;">{{DefaultPath+SEOPage.PageRoute}}</v-list-item-title>
                        <v-list-item-subtitle class="text-h7">
                        <v-btn  class="underlinehover" style="padding-left:0px;text-transform: capitalize;font-size: 0.925em;color: #1a0dab!important;" text plain depressed>
                            <span>{{System.Name}} || </span>
                        <span v-if="ActiveGroup && $route.name === 'GroupPageEditor'">{{ActiveGroup.name}} || </span> {{SEOPage.Name}} <v-icon color="#1a0dab" small dark>{{SEOPage.PageIcon}}</v-icon></v-btn>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="display: block;" v-html="SEOPage.Overview">
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    </v-list-item>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="DeactivateSEODialog()" dark color="warning">
                    Cancel</v-btn>
                    <v-spacer>
                    </v-spacer>
                    <v-btn  @click="UpdateSEOBasics()"  dark color="success">
                        Save
                    </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-navigation-drawer :app="!MiniView" hide-overlay
                    mini-variant v-model="SiteDialog"
                    :permanent="!MiniView || MiniView && SiteDialog" mini-variant-width="100"
                    :style="!$route.meta.DataSingleBuilder? 'z-index:8;height: 100%;top:65px;position: fixed;'
                    : 'z-index:2;height: 100%;top:190px;position: fixed;'"
                   >	
                    <v-list-item class="px-2">
                    <v-list-item-avatar>
                    <v-img :src="GroupData && GroupData.logo ? GroupData.logo : require('@/assets/logo.png')"></v-img>
                    </v-list-item-avatar>
                </v-list-item>
                <v-divider></v-divider>
                <v-list dense>
                    <v-list-item
                    v-for="main in Computedsitenavitems"
                    :key="main.itemObjKey"
                    link
                    >
                     <v-btn @click="ActivateSelectedMain(main)">
                        <v-icon>{{main.icon}}</v-icon>
                            <v-badge color="warning" v-if="main.notifications" left>
                        <span slot="badge"> {{main.notifications}}</span>
                        </v-badge>
                    </v-btn>
                    </v-list-item>
                    
                </v-list>
                </v-navigation-drawer>
             <v-navigation-drawer :app="MiniView" class="elevation-2" :style="!$route.meta.DataSingleBuilder? 'position: fixed;left: 88px;top:65px;z-index:6;height:60%;'
             : 'position: fixed;left: 88px;top:190px;z-index:6;height:60%;'" v-if="SelectedMainDialog" v-model="SelectedMainDialog">
                <v-list v-if="SelectedMain && SelectedNavbar">
                        <v-card flat>
                            <v-card-title class="settingsfont">
                                {{SelectedMain.title}}<v-spacer></v-spacer><v-icon @click="SelectedMain = ''">mdi-chevron-left</v-icon>
                            </v-card-title>
                            <v-card-text class="settingsfont" v-if="SelectedMain.title === 'Page Layout'">
                                <v-list dense class="transparent">					
                                    <v-list v-for="(tab,i) in PageTabs" :key="tab.itemObjKey">									
                                    <v-list-item @click="ShowTabElements(i)"									
                                    @dragover.prevent @drop.prevent="Tabdragfinish(i, $event)">									
                                        <v-list-item-avatar size="20">									
                                                    <v-icon v-if="tab.Elements.length>0" small style="display:block;" :id="i+'ShowElementsConfig'" @click="ShowTabElements(i)">mdi-chevron-down</v-icon>									
                                                    <v-icon small style="display:none;" :id="i+'HideElementsConfig'" @click="HideTabElements(i)">mdi-chevron-up</v-icon>									
                                                </v-list-item-avatar>									
                                        <v-list-item-title  :draggable="true" @dragstart="Tabdragstart(tab,i, $event)" @click="OpenDialog(tab,i)">{{tab.Name? tab.Name : tab.DataViewName}}</v-list-item-title>									
                                            <v-list-item-avatar size="20" @click.prevent="AdjustRowAmount(tab,i)">									
                                                    <v-icon small class="links--text actionicon">mdi-plus-thick</v-icon>									
                                                </v-list-item-avatar>									
                                                									
                                                									
                                    </v-list-item>									
                                    <v-list :id="i+'ElementsConfig'" style="display:none;padding-left:40px;">									
                                                <v-list-item									
                                                    @click="OpenDialog(elmnt,elmntindex,true,i),ShowTabElements(i)"									
                                                    v-for="(elmnt, elmntindex) in tab.Elements"									
                                                    :key="elmnt.itemObjKey"									
                                                    link									
                                                    @dragover.prevent @drop.prevent="Elmntdragfinish(tab,i,elmnt,elmntindex, $event),ShowTabElements(i)"									
                                                >									
                                                    <v-list-item-title v-text="elmnt.Name? elmnt.Name : elmnt.DataViewName"									
                                                    :draggable="true" @dragstart="Elmntdragstart(tab,i,elmnt,elmntindex, $event)"></v-list-item-title>									
									
                                                    <v-list-item-icon>									
                                                    <v-icon class="blue--text" v-text="elmnt.Icon"></v-icon>									
                                                    </v-list-item-icon>									
                                                </v-list-item>									
                                    </v-list>									
                                    </v-list>		
                                </v-list>
                            </v-card-text>
                            <v-card-text class="settingsfont" v-if="SelectedMain.title === 'Add Content'">
                                 <v-row dense>									
                                <v-col cols="6" md="4"									
                                 v-for="block in RenderedContentBlocks" :key="block.itemObjKey">									
                                    <v-card outlined height="100" class="mx-2" width="100%" @dragend="NoPropsemit('CancelDrag')"									
                                :draggable="true" @dragstart="blockdragstart(block, $event)"> 
                                    <v-list-item two-line>
                                        <v-list-item-content >
                                        <v-avatar tile size="40">
                                            <v-icon size="40">{{block.Icon}}</v-icon>
                                        </v-avatar>

                                        <v-card-subtitle style="text-align:center;line-height:1;padding:0;" class="caption">
                                        {{block.DataViewName? block.DataViewName : block.Name}} 
                                        </v-card-subtitle>
                                </v-list-item-content>
                                    </v-list-item>
                            </v-card>									
                                    </v-col>									
                                </v-row>
                            </v-card-text>
                            <v-card-text class="settingsfont" v-if="SelectedMain.title === 'Site Menu & Pages'">
                                <v-overflow-btn style="width:200px;padding-top:5px;"
                                :items="NavbarItems" :placeholder="'Navbar: '+SelectedNavbar.Name" v-model="SelectedNavbar"
                                item-text="Name"
                                    class="elevation-0 noborderoverflow"
                                    dense return-object
                                    ></v-overflow-btn>
                                    <v-list class="transparent">
                                        <v-list-item style="padding-left: 0px;">
                                            <v-chip @click="AddNewPage(SelectedNavbar)" >Add Page <v-icon>mdi-plus</v-icon></v-chip>
                                            <v-chip @click="UpdateMenu()" dark color="success"><v-icon>mdi-content-save</v-icon>Save
                                            </v-chip>
                                        </v-list-item>
                                <v-list-item  @change="SelectOtherPage(page)" :class="PageClass(page)" @dragend="CancelNavItemDrag()"
                                 @dragover.prevent  @drop.prevent="page.OnMenu ? NavItemDragFinish(pageindex, $event) : ''"									
                                :draggable="page.OnMenu" @dragstart="NavItemDragStart(pageindex, $event)"
                                dense v-for="(page,pageindex) in ComputedNavbarPages" :key="page.itemObjKey"
                                :style="SelectedPage.Name === page.Name ? 'border: 1px solid #89bcd9 !important;border-radius: 6px;cursor: all-scroll !important;color: links !important;'
                                : 'border: 1px solid #eee !important;border-radius: 6px;cursor: all-scroll !important;color: links !important;'"
                                >
                                
                                <v-list-item-avatar class="sitenavitembtns" style="margin-right: 0px !important;" size="20">
                                         <v-icon  small>mdi-drag</v-icon> 
                                    </v-list-item-avatar>
                                    <v-list-item-avatar style="margin-right: 0px !important;" size="20">
                                         <v-icon small>{{page.PageIcon}}</v-icon> 
                                    </v-list-item-avatar>
                                    <v-list-item-content style="padding: 4px;" :class="page.RenderStyling" >
                                        <span v-if="page.Name.length > 18">
                                      {{page.Name.substr(0,15)}}...
                                        </span>
                                        <span v-else>
                                             {{page.Name}}
                                        </span>
                                    </v-list-item-content>
                                    <v-list-item-actions>
                                        <v-icon v-if="page.MenuIndex === 100000" small>mdi-eye-off-outline</v-icon>
                                        <v-icon v-if="page.MenuIndex !== 100000" small>mdi-eye</v-icon>
                                        
                                    </v-list-item-actions>
                                    <v-list-item-actions>
                                        <v-menu nudge-right="30"								
                                            :close-on-content-click="false"                            									
                                            transition="scale-transition"                            									
                                            >									
                                            <template v-slot:activator="{ on }">	
                                            <v-btn 
                                            v-on="on"
                                            icon fab small
                                            >
                                             <v-icon small>mdi-dots-horizontal-circle-outline</v-icon>
                                            </v-btn>
                                            </template>
                                            <v-card flat width="150">
                                                <v-list style="padding: 0px;" dense class="transparent">
                                                    <v-list-item class="settingsfont" dense @click="page.MenuIndex === 100000 ? PushPagetoNavlistArray(page) :RemovePagefromNavlistArray(page)">
                                                        <v-icon style="padding:5px;" size="15">
                                                            {{page.MenuIndex === 100000 ? 'mdi-eye' : 'mdi-eye-off'}}
                                                        </v-icon>
                                                        {{page.MenuIndex === 100000 ? 'Show' : 'Hide'}}
                                                    </v-list-item>
                                                    <v-list-item class="settingsfont" dense v-if="page.MenuIndex !== 100000">
                                                        <v-icon style="padding:5px;" size="15">
                                                             mdi-subdirectory-arrow-right
                                                        </v-icon>
                                                        Subpage
                                                    </v-list-item>                                                    
                                                    <v-menu nudge-left="100"								
                                                        :close-on-content-click="false"                            									
                                                        transition="scale-transition"                            									
                                                        >									
                                                        <template v-slot:activator="{ on }">
                                                            <v-list-item v-on="on" class="settingsfont" dense>
                                                                <v-icon style="padding:5px;" size="15">
                                                                    mdi-pencil
                                                                </v-icon>
                                                                Rename
                                                            </v-list-item>
                                                        </template>
                                                        <v-card>
                                                            <v-card-title class="settingsfont">
                                                               Rename<v-spacer></v-spacer>
                                                               <v-btn dark color="success" small @click="UpdatePageProp(page,'Name')">Save</v-btn>
                                                            </v-card-title>
                                                            <v-card-text>
                                                                <v-text-field label="Page Name" v-model="page.Name"
                                                                />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-menu>
                                                    <v-menu nudge-left="100"								
                                                        :close-on-content-click="false"                            									
                                                        transition="scale-transition"                            									
                                                        >									
                                                        <template v-slot:activator="{ on }">
                                                            <v-list-item v-on="on" class="settingsfont" dense>
                                                                <v-icon style="padding:5px;" size="15">
                                                                    mdi-link
                                                                </v-icon>
                                                                Path
                                                            </v-list-item>
                                                        </template>
                                                        <v-card>
                                                            <v-card-title class="settingsfont">
                                                               Path<v-spacer></v-spacer>
                                                               <v-btn dark color="success" small @click="UpdatePageProp(page,'PageRoute')">Save</v-btn>
                                                            </v-card-title>
                                                            <v-card-text>
                                                                <v-text-field label="Page Path" v-model="page.PageRoute"
                                                                />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-menu>
                                                    <v-menu nudge-left="100"								
                                                        :close-on-content-click="false"                            									
                                                        transition="scale-transition"                            									
                                                        >									
                                                        <template v-slot:activator="{ on }">
                                                            <v-list-item v-on="on" class="settingsfont" dense>
                                                                <v-icon style="padding:5px;" size="15">
                                                                    mdi-circle
                                                                </v-icon>
                                                                Icon
                                                            </v-list-item>
                                                        </template>
                                                        <v-card>
                                                            <v-card-title class="settingsfont">
                                                               Icon
                                                               <v-spacer></v-spacer>
                                                               <v-btn dark color="success" small @click="UpdatePageProp(page,'PageIcon')">Save</v-btn>
                                                            </v-card-title>
                                                            <v-card-text>
                                                                <v-text-field label="Page Icon" v-model="page.PageIcon"
                                                                />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-menu>
                                                    <v-list-item class="settingsfont" dense @click="ActivateSEOBasicsDialog(page)">
                                                        <v-icon style="padding:5px;" size="15">
                                                            mdi-search-web
                                                        </v-icon>
                                                        SEO Basics
                                                    </v-list-item>
                                                    
                                                    <v-list-item class="settingsfont" dense>
                                                        <v-icon color="red" style="padding:5px;" size="15">
                                                            mdi-delete-forever
                                                        </v-icon>
                                                        Delete
                                                    </v-list-item>
                                                </v-list>
                                            </v-card>
                                        </v-menu>
                                     
                                     </v-list-item-actions>
                                </v-list-item>
                                    </v-list>
                            </v-card-text>
                            <v-card-text class="settingsfont" v-if="SelectedMain.title === 'Page Design'">
                                <v-list-item @click="ActivatePageBGDialog()" >
                                <span v-if="PageBGAsset" style="--col: #04AA6D"  class="texbtn btnhover elevation-1">
                            <v-icon style="--col: #04AA6D"  class="btnhover" small>mdi-file</v-icon>Change BG Image
                            </span><span v-if="!PageBGAsset" style="--col: #04AA6D"  class="texbtn btnhover elevation-1">
                                <v-icon  style="--col: #04AA6D" class="btnhover" small>mdi-file</v-icon>Select BG Image
                            </span>
                                </v-list-item>
                            <v-select v-model="PageHeadersFont" label="Headers Font"                                  
                                :items="FontOptions">
                                <template v-slot:item="{ item }">
                                    <span :style="'font-family:'+item">{{item}}
                                        <p style="font-size:12px;">The Quick brown fox jumps over the lazy dog
                                        </p>
                                        </span>
                                </template>
                                <template v-slot:selection="{ item }">
                                    <span :style="'font-family:'+item">{{item}}</span>
                                </template>
                                </v-select>
                                <v-select v-model="PageContentFont" label="Body Font"                                   
                                    :items="FontOptions">
                                <template v-slot:item="{ item }">
                                    <span :style="'font-family:'+item">{{item}}
                                        <p style="font-size:12px;">The Quick brown fox jumps over the lazy dog
                                        </p>
                                        </span>
                                </template>
                                <template v-slot:selection="{ item }">
                                    <span :style="'font-family:'+item">{{item}}</span>
                                </template>
                                </v-select>	
                                <v-select label="Header size" v-model="PageHeadersFontSize" :items="[24,36,42,50]"									
                                    />	
                            <v-divider vertical></v-divider>
                                    <v-autocomplete :items="STDCSSColors" clearable label="Page BG Color" item-text="Name" v-model="PageBGColor">
                                        <template v-slot:item="{ item }">
                                            <span :style="'background-color:'+item.HEX">{{item.Name}}
                                                <p style="font-size:12px;">The Quick brown fox jumps over the lazy dog
                                                </p>
                                                </span>
                                        </template>
                                    </v-autocomplete>
                            <v-spacer></v-spacer>
                                <v-list-item>
                                    <v-text-field label="Page BG Gradient" @change="UpdatePageBGGradient(PageBGGradient)" clearable v-model="PageBGGradient"/>
                                </v-list-item>
                                <v-list-item>									
                                    <a href="https://cssgradient.io/" target="_blank">Get Code</a>	
                                </v-list-item>
                            </v-card-text>
                            <v-card-text class="settingsfont" v-if="SelectedMain.title === 'Media'">
                                <AssetsDirectory :Assets="SystemAssets" :Cols="6" :HoverPreview="true" @ActivateFullGallery="ActivateFullGallery" :ViewFullGalleryBottom="true"
                                :HideSelect="true" :Slice="6"
                                 :ViewFullGalleryText="'See More'"
                                />
                                
                            </v-card-text>
                        </v-card>
                    </v-list>
                </v-navigation-drawer>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ContentEditableField from '@/components/Database/ContentEditableField'
import AssetsDirectory from '@/components/WebPages/ConfigComponents/AssetsDirectory';	

export default {
    props: ['System','SystemEntities'],
    components: {
        ContentEditableField,
        AssetsDirectory
    },
    data() {
        return {
            SelectedModule: {
                Name:' Page Builder'
            },
            SEOPage: {
                Overview: '',
                PageRoute: '',
                KeyWords: [],
                Name: '',
                PageIcon: '',
                SEO: false,
            },
            DefaultSEOPage: {
                Overview: '',
                PageRoute: '',
                KeyWords: [],
                Name: '',
                PageIcon: '',
                SEO: false,
            },
            SEOBasicsDialog: false,
        }
    },
    computed:{
        SiteDialog(){
            return this.$store.state.SiteDialog
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
        Computedsitenavitems(){
            return this.sitenavitems.filter(item => {
                if(this.$route.meta.DataSingleBuilder){
                    return item.title === 'Add Content' || item.title === 'Page Layout'
                }
                else if(this.$route.name === 'newlandingpage'){
                    
                    return item.title !== 'Site Menu & Pages'
                }
                else{
                    return item
                }
            })
        },
        ComputedNavbarPages(){
            return this.NavbarPages.map(page => {
                page.OnMenu = false
                let oncheck = this.ComputedNavList.find(obj => obj.Path === page.PageRoute)
                if(oncheck){
                    page.MenuIndex = oncheck.MenuIndex
                    page.OnMenu = true
                }
                else{
                    page.OnMenu = false
                    page.MenuIndex = 100000
                }
                return page
            })
            .map(page => {
                if(page.OnMenu){
                    page.RenderStyling = 'links--text'
                }
                else{
                    page.RenderStyling = 'grey--text'
                }
                return page
            })
            .sort((a, b) => {
                var key1 = a.MenuIndex;
                var key2 = b.MenuIndex;

                if (key1 < key2) {
                    return -1;
                } else if (key1 == key2) {
                    return 0;
                } else {
                    return 1;
                }
        })
        },
        ActiveGroup(){
            return this.$store.state.ActiveGroup
        },
        ComputedSitePages(){
            if(this.$route.name === 'GroupPageEditor'){
                return this.ActiveGroup && this.ActiveGroup.Pages ? this.ActiveGroup.Pages : []
            }
            else{
                return this.SitePages
            }
        },
        NavListArray(){
            return this[this.NavlistHolder] && this.SelectedNavbar && this[this.NavlistHolder][this.SelectedNavbar.Prop] ? this[this.NavlistHolder][this.SelectedNavbar.Prop]
            : []
        },
        NavlistRef(){
            return this.NavlistHolder === 'ActiveGroup' && this.ActiveGroup ? db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(this.ActiveGroup.id) : 
            db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
        },
        NavlistHolder(){
            if(this.$route.name === 'GroupPageEditor'){
                return 'ActiveGroup'
            }
            else{
                return 'System'
            }
        },
        ComputedNavList(){
            let arr = this.NavListArray
            //console.log(arr)
            return arr.map((item,arrindex) => {
                item.MenuIndex = arrindex
                return item
            })
        },
        NavbarPages(){
            if(this.$route.name === 'GroupPageEditor'){
                return this.ComputedSitePages
            }
            else{
             return this.ComputedSitePages.filter(page => {
                 if(this.SelectedNavbar){
                     return page.PublishType === this.SelectedNavbar.PublishType
                 }
                 else{
                     return page
                 }
            })   
            }
            
        },
        DefaultPath(){
            //UpdateSEOBasics
            if(this.$route.name === 'PageEditor'){
                return this.System.ClientAppURL+'/'
            }
            else if(this.$route.name === 'GroupPageEditor'){
                return this.System.ClientAppURL+'/Group-Page/'+this.$route.params.id+'/'
            }
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
            FieldObject[prop] = value
            console.log(AdditionalSaveMethod)
            if(AdditionalSaveMethod){
                this[AdditionalSaveMethod]()
            }
        },
        DeactivateSEODialog(){
            this.SEOBasicsDialog = false
            this.SEOPage = Object.assign({},this.DefaultSEOPage)
        },
        UpdateSEOBasics(){
            let updatepath = ''
            if(this.$route.name === 'PageEditor'){
                updatepath = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Pages').doc(this.SEOPage.id)
            }
            else if(this.$route.name === 'GroupPageEditor'){
                updatepath = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(this.$route.params.id).collection('pages').doc(this.SEOPage.id)
            }
            updatepath.update({
                Overview: this.SEOPage.Overview,
                KeyWords: this.SEOPage.KeyWords,
                SEO: this.SEOPage.SEO
            }).then(updatedpage => {
                this.DeactivateSEODialog()
            })
        },
        ActivateSEOBasicsDialog(page){
            this.SEOPage = {
                Overview: page.Overview,
                KeyWords: [],
                PageRoute: page.PageRoute,
                Name: page.Name,
                Icon: page.PageIcon,
                id: page.id,
                SEO: false,
            }
            if(page.KeyWords){
                this.SEOPage.KeyWords = page.KeyWords
            }
            if(page.SEO){
                this.SEOPage.SEO = page.SEO
            }
            this.SEOBasicsDialog = true
        },
        UpdatePageProp(page,Prop){
            let updatepath = ''
            if(this.$route.name === 'PageEditor'){
                updatepath = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Pages').doc(page.id)
            }
            else if(this.$route.name === 'GroupPageEditor'){
                updatepath = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(this.$route.params.id).collection('pages').doc(page.id)
            }
            updatepath.update({
                [Prop]: page[Prop]
            })
        },
        ActivatePageBGDialog(){
            this.$store.commit('setGalleryLibraries',this.GalleryLibraries)
            this.$store.commit('setGalleryAssets',this.SystemAssets)
            this.$store.commit('setEditedTab','')
            this.$store.commit('setIMGProp','PageBGAsset')
            this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        },
        ActivateFullGallery(){
            this.$store.commit('setGalleryLibraries',this.GalleryLibraries)
            this.$store.commit('setGalleryAssets',this.SystemAssets)
            this.$store.commit('setEditedTab','')
            this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        },
        RemovePagefromNavlistArray(page){
            let item = this[this.NavlistHolder][this.SelectedNavbar.Prop].find(obj => obj.title === page.Name)
            if(item){
                item.OnMenu = false
                item.MenuIndex = 100000
                let index = this[this.NavlistHolder][this.SelectedNavbar.Prop].indexOf(item)
                this[this.NavlistHolder][this.SelectedNavbar.Prop].splice(index,1)
            }
            this.SelectedMainDialog = false
            setTimeout(() => {
                    this.SelectedMainDialog = true
            }, 10);
        },
        PushPagetoNavlistArray(page){
            let navpage = {
                Path: page.PageRoute,
                title: page.Name,
                MenuIndex: this[this.NavlistHolder][this.SelectedNavbar.Prop].length
            }
            if(page.PageIcon){
                navpage.icon = page.PageIcon
            }
            this[this.NavlistHolder][this.SelectedNavbar.Prop].push(navpage)
            this.SelectedMainDialog = false
            setTimeout(() => {
                    this.SelectedMainDialog = true
            }, 10);
        },
        UpdateMenu(){
            if(this.NavListArray){
             this.NavlistRef.update({
                [this.SelectedNavbar.Prop]: this.NavListArray
            })   
            }
            
        },
        PageClass(page){
            if(this.SelectedPage.Name === page.Name){
                if(this.$vuetify.theme.dark){
                   return 'background lighten-2 sitenavitem' 
                }                
            }
            else{
                if(this.$vuetify.theme.dark){
                return 'background sitenavitem'
                }
                else{
                 return 'sitenavitem'   
                }
            }
        },
        
        AddNewPage(navbar){
            console.log(navbar)
            let pageitems =  [{
            title: 'Create Website Page',
            icon: 'mdi-search-web',
            ItemPass: true,
            Method: 'AssignSocialItemInteractMethod',
            Prop: 'ToggleNewPageDialog',
            SecondProp: 'Public'
          },
          {
            title: 'Create Social Page',
            icon: 'mdi-search-web',
            ItemPass: true,
            Method: 'AssignSocialItemInteractMethod',
            Prop: 'ToggleNewPageDialog',
            SecondProp: 'Members Only'
          },]
          if(this.$route.name === 'GroupPageEditor'){
              let pubtype = ''
              if(this.GroupData.GroupPrivacy === 'Public'){
                  pubtype = 'Public'
              }
              else{
                  pubtype = 'Members Only'
              }
              this.AssignSocialItemInteractMethod('ToggleNewPageDialog',pubtype)
          }
          else{
              let item = pageitems.find(obj => obj.SecondProp === navbar.PublishType)
              this.AssignSocialItemInteractMethod('ToggleNewPageDialog',navbar.PublishType)
          }
        },
        AssignSocialItemInteractMethod(item,SecondProp){
        //console.log('AssignSocialItemInteractMethod',item,SecondProp)
        this.$store.commit('SetSocialItemInteractMethodProp','') 
        this.$store.commit('SetSocialItemInteractMethod','')
        if(SecondProp){
            this.$store.commit('SetSocialItemInteractMethodProp',SecondProp) 
            this.$store.commit('SetSocialItemInteractMethod',item)
        }
        else if(item){
            this.$store.commit('SetSocialItemInteractMethod',item)
        }
        },
        AssignRenderedContentBlocks(blocks){
            //console.log('sure we assign blocks',blocks)
            //THIS BECOMES STORE COMMIT
            this.RenderedContentBlocks = blocks
        },
        ActivateSelectedMain(main){
            this.SelectedMain = main
        },
        SelectOtherPage(page){
            this.EditOtherPage(page)
        },
        EditOtherPage(SelectedPage){
            if(this.$route.name === 'GroupPageEditor'){
                this.$router.push('/Group-PageEditor/'+this.$route.params.id+'/'+SelectedPage.id)
            }
            else{
                this.$router.push('/PageEditor/'+SelectedPage.id)
            }
        },
        ActivateStyleButton(btn,tab){
            this.EditedTab = tab
            this.ActiveButtoncompname = btn.compname
            this.ActiveButtonName = btn.Name
            this.SelectedActiveButton = btn
            // this.MiniStyler = true
            this.TabDialog = true
        },
        TabStyleItems(tab){
            // console.log('TabStyleItems',tab)
            if(tab && !tab.DataViewName){
                return this.StyleItems.filter(item => {return item.Children.includes(tab.Name) || item.GlobalQualifier && this[item.GlobalQualifier]}).filter(item => {
                    let conditionalchild = item.Conditions.find(cond => {return cond.Child === tab.Name})
               
                    return !conditionalchild && !item.DisqualifyingProp || !conditionalchild && item.DisqualifyingProp && !tab[item.DisqualifyingProp]
                    || conditionalchild && conditionalchild.ConditionType === 'Prop' && tab[conditionalchild.Condition] && !item.DisqualifyingProp || 
                    conditionalchild && conditionalchild.ConditionType === 'Prop' && tab[conditionalchild.Condition] && item.DisqualifyingProp && !tab[item.DisqualifyingProp] ||
                    conditionalchild && conditionalchild.ConditionType === 'Method' && this[conditionalchild.Condition](tab) && !item.DisqualifyingProp || 
                    conditionalchild && conditionalchild.ConditionType === 'Method' && this[conditionalchild.Condition](tab) && item.DisqualifyingProp && !tab[item.DisqualifyingProp]
                    || item.GlobalQualifier && this[item.GlobalQualifier]
                }).map((item,index) => {
                    item.Index = index
                    return item
                })
            }
            else if(tab && tab.DataViewName){
                return this.StyleItems.filter(item => {return item.Children.includes(tab.DataViewName)})
            }
            else{
                return []
            }
        },
        AddContentDialog(EditedTab){
            if(EditedTab.ContentDialogType){
                let contentbox = this.ContentBlocks.find(obj => obj.Name === EditedTab.ContentDialogType.Name)
                EditedTab.ContentDialogBox = Object.assign({},contentbox)
                EditedTab.ContentDialogBox.Elevation = 0
                EditedTab.ContentDialogBox.Tile = true
                EditedTab.ContentDialogBox.Flat = true
                EditedTab.ContentDialogBox.IsContentDialog = true
            }
        },
    }
}
</script>

<style>

</style>



